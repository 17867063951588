html {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  /* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
  background-color: #000000;
  height: 100%;
  overflow: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro");

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  user-select: none;
}

p,
span,
a,
button {
  user-select: none;
}

.topNavbar {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  /* background: rgba(0, 0, 0, 0.2); */
  /* backdrop-filter: blur(20px); */
  height: 50px;
  width: 100%;
  z-index: 2;
}

.user-info {
  position: absolute;
  top: 8px;
  right: 35px;
  text-align: right;
  color: #fff;
  z-index: 100;
}

.user-info.holder {
  color: red !important;
}
.user-info.holder h3 {
  color: red;
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  body {
    /* height: 100vw;
    width: 100vh; */
    /* left: 100vw; */
    /* transform-origin: left top; */
    /* transform: rotate(90deg); */
  }
}

@media (max-width: 767px) {
  #maxButton {
    display: none !important;
  }
}

.ui.modal > .close {
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  opacity: 0.8;
  font-size: 1.25em;
  color: #fff;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.625rem 0 0 0;
}
.ui.dimmer {
  background-color: transparent;
}

.register-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 31vh;
  align-items: center;
  height: 100vh;
}
.register-container .title {
  font-family: HelveticaNeue;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4em;
  text-align: center;

  border-right: solid 10px white;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;

  animation: animated-text 4s steps(29, end) 1s 1 normal both,
    animated-cursor 600ms steps(29, end) infinite;
}
.register-container .titleB {
  font-family: HelveticaNeue;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.3em;
  text-align: center;

  border-right: solid 10px white;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;

  animation: animated-textB 4s steps(29, end) 1s 1 normal both,
    animated-cursor 600ms steps(29, end) infinite;
}
@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 420px;
  }
}
@keyframes animated-textB {
  from {
    width: 0;
  }
  to {
    width: 700px;
  }
}

.modal.show .modal-dialog {
  transform: none;
  height: 100vh;
  display: flex;
  align-items: center;
}
.modal-content {
  background-color: transparent;

  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  border: none !important;
}
.modal-dialog {
  max-width: 650px;
  margin: 1.75rem auto;
}

.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
}

.dropdown-menu {
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.48);
  border: 1px solid #555555;
  box-sizing: border-box;
  box-shadow: 8px 16px 64px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(16px);
  width: 97px !important;
  height: 40px !important;
  background: #0000007a !important;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  color: white;
}
.dropdown-item:hover {
  background-color: transparent !important;
  color: white !important;
}

.loadingIconCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shareCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shareClose {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.shareBody {
  width: 711px;
  height: 415px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  text-align: center;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 47px 0 85px;
  position:relative;
}
.shareTitle {
  font-family: 'SpaceGrotesk-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  
  text-align: center;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  
  color: #F5F5F5;
}
.shareCommand{
  font-family: 'PPNeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  text-align: center;
  letter-spacing: 0.05em;
  /* White */
  color: #FFFFFF;
}

.shareEsc{
  flex-direction: column;
  align-items: center;
  /* padding: 14px 10px; */
  width: 43px;
  height: 43px;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  margin: auto;
  display: flex;
  justify-content: center;
  font-family: 'PPNeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 111% */
  text-align: center;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
}
.shareContent {
  font-family: NeueHaasDisplay-Mediu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: center;
  letter-spacing: 0.05em;

  /* White */

  color: #ffffff;
}
.shareBtnShare {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 202px;

  width: 409px;
  height: 67px;

  font-family: 'SpaceGrotesk-Bold';
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #000000;
  background: #ffffff;
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
}
.shareBtnShare:active{
  background: transparent;
  border: 1px solid white;
  color: white;
  
}

.shareBtnDown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 202px;

  width: 409px;
  height: 67px;

  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
  font-family: 'SpaceGrotesk-Bold';
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.shareBtnDown:active{
  background: #ffffff;
  color: #000000;
}
.shareBtnCopy{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px;

  width: 181px;
  height: 66px;

/* White */

  background: #FFFFFF;
  border-radius: 4px;
  font-family: SpaceGrotesk-Bold;
  font-size: 18px;
  line-height: 23px;
  margin-left: -3px;
/* identical to box height */

  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;

  color: #000000;
  cursor: pointer;

}
.shareBtnCopy:active{
  border: 1px solid white;
  background-color: transparent;
}
.shareCopyBox{
  display: flex;
  justify-content: center;
}
.shareInput {
  width: 381px;
  height: 66px;
  outline: none;
  border: solid 1px white;
  padding-left: 20px;
  letter-spacing: 0.01em;
  color: rgb(255, 255, 255);
  background-color: transparent;
  font-family: MADEOuterSans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: left;
}

.centerCenter{
  display: flex;
  text-align: center;
  justify-content: center;
}
.centerBetween{
  display: flex;
  text-align: center;
  justify-content: space-between;
}
/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: grey;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::selection {
  background-color: unset !important;
} */

.leaderBoard ::-webkit-scrollbar-thumb {
  background: #ffffff !important;
}
.leaderBoard ::-webkit-scrollbar {
  width: 6px!important;
  height: 8px;
}
.leaderBoard ::-webkit-scrollbar-track {
  background: #585858!important;
  border-radius: 10;
}

.termModal ::-webkit-scrollbar-thumb {
  background: #ffffff !important;
}
.termModal ::-webkit-scrollbar {
  width: 6px!important;
  height: 8px;
}
.termModal ::-webkit-scrollbar-track {
  background: #939494!important;
  border-radius: 10;
}

.projectTT ::-webkit-scrollbar-thumb {
  background: #dad9d9 !important;
}
.projectTT ::-webkit-scrollbar {
  width: 6px!important;
  height: 8px;
}
.projectTT ::-webkit-scrollbar-track {
  background: #939494!important;
  border-radius: 10;
}
